import React, { forwardRef, useImperativeHandle } from "react";
import * as htmlToImage from "html-to-image";
import "./Receipt.css"; // Import CSS for styling

const Receipt = forwardRef((props, ref) => {
  const shareReceipt = async () => {
    const receiptNode = document.getElementById("receipt");

    try {
      // Temporarily display node if hidden
      receiptNode.style.display = "block";

      //   if (navigator.share) {
      //     // Generate image and convert to blob
      //     const dataURL = await htmlToImage.toPng(receiptNode, {
      //       skipFonts: true, // Skips embedding web fonts to avoid CORS issues
      //     });
      //     const blob = await (await fetch(dataURL)).blob();

      //     // Use Web Share API
      //     await navigator.share({
      //       files: [new File([blob], `${timeStamp()}.png`, { type: blob.type })],
      //       title: "Receipt",
      //       text: "Here is your transaction receipt",
      //     });
      //   } else {
      // Fallback for devices without Web Share API
      const dataURL = await htmlToImage.toPng(receiptNode, { skipFonts: true });
      const link = document.createElement("a");
      link.download = `${timeStamp()}.png`;
      link.href = dataURL;
      link.click();
      //   }
    } catch (error) {
      console.error("Error generating or sharing receipt image:", error);
      alert("An error occurred while sharing the receipt.");
    } finally {
      receiptNode.style.display = "none"; // Hide receipt node after share attempt
    }
  };

  const timeStamp = () => new Date().toISOString().replace(/[:.]/g, "-");

  useImperativeHandle(ref, () => ({
    shareReceipt,
  }));

  return (
    <div id="receipt" className="receipt">
      <div className="header">
        <div style={{ fontSize: "18px" }}>
          <strong>{props.business}</strong>
        </div>
        <h1>
          <strong>{props.unit}</strong>
        </h1>
        <p style={{ color: props.status === "success" ? "green" : "orange" }}>
          {props.status === "success" ? "Successful" : "Processing"} Transaction
        </p>
        <p>
          <strong>{props.date}</strong>
        </p>
      </div>

      <div className="section">
        <h2>Recipient:</h2>
        <p>{props.recipient}</p>
      </div>

      <div className="section">
        <h2>Transaction Info</h2>
        <p>
          <strong>Transaction Type:</strong> {props.service.toUpperCase()}
        </p>
        <p>
          <strong>Transaction ID:</strong> {props.refNo}
        </p>
      </div>

      <footer>
        <p>Enjoy Seamless service.</p>
      </footer>
    </div>
  );
});

export default Receipt;
