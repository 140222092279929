import "./Login.css";

import walletInit from "../../../pictures/icn/wallet_blue.png";
import walletCur from "../../../pictures/icn/wallet_green.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Toast } from "../../alert";
import Spinner from "../../spinner";
import request from "superagent";
import { baseApiUrl } from "../../../services/setup";
import "./Transactions.css";
import { Button } from "@mui/material";
import Receipt from "../components/Reciept";

const MobileTransactions = () => {
  const navigate = useNavigate();

  const userId = localStorage.getItem("email");
  const [init, setInit] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = {
          email: userId,
        };

        const response = await request
          .post(baseApiUrl + "transactions.php")
          .type("application/json")
          .send(req);

        const theData = response.body;
        // console.log(theData);

        setRows(theData);
        setInit(true);
      } catch (err) {
        // console.error("Error message:", err.response);
        // console.error("ERROR", err);
      }
    };

    fetchData();
  }, [init]);

  return (
    <div className="d-flex flex-column align-items-center mt-4">
      <div>
        <strong>TRANSACTIONS</strong>
      </div>
      <div className="body-section">
        {rows.map((row, id) => {
          return (
            <TransactionCard
              key={id + 1}
              refNo={row.refNo}
              details={row.details}
              initBalance={row.prevBal}
              curBalance={row.newBal}
              amount={row.amount}
              date={row.date}
              transMode={row.mode}
              transType={row.service}
              status={row.status}
              unit={row.serviceUnit}
              recipient={row.recipient}
            />
          );
        })}
      </div>
    </div>
  );
};

const TransactionCard = ({
  status,
  refNo,
  transType,
  details,
  initBalance,
  curBalance,
  transMode,
  amount,
  date,
  unit,
  business,
  recipient,
}) => {
  const receiptRef = useRef();

  const handlePrintReceipt = () => {
    if (receiptRef.current) {
      receiptRef.current.shareReceipt(); // Trigger shareReceipt function
    }
  };

  return (
    <div style={{ fontSize: "small" }} className="card m-2 p-2 shadow-1">
      <table>
        <tr>
          <td colSpan="2">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <strong style={{ textTransform: "uppercase" }}>
                {transType}
              </strong>
              <div
                style={{
                  fontSize: "14px",
                  color: "green",
                  textTransform: "uppercase",
                }}
              >
                <strong>{status}</strong>
              </div>
            </div>
            <div>{details}</div>
          </td>
          <td></td>
        </tr>

        <tr>
          <td>
            <div style={{ fontSize: "smaller" }}>
              <img src={walletInit} className="wallet-icon" alt="w" />
              Initial balance:
            </div>
            <div style={{ color: "darkblue" }}>
              <strong>N{initBalance}</strong>
            </div>
          </td>
          <td>
            <div style={{ fontSize: "smaller" }}>
              <img src={walletCur} className="wallet-icon" alt="w" />
              Current balance:
            </div>
            <div style={{ color: "darkgreen" }}>
              <strong>N{curBalance}</strong>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div>
              <strong>{transMode}</strong>
            </div>
          </td>
          <td>
            <div>
              Amount: <strong style={{ color: "darkred" }}>N{amount}</strong>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div>Reference: {refNo}</div>
          </td>
          <td>
            <div>Date: {date}</div>
          </td>
        </tr>
      </table>

      <Button
        className="w-50"
        variant="contained"
        sx={{
          textTransform: "none",
          color: "#fff",
          fontSize: "12px",
          background: "#ff6600",
          "&:hover": { background: "#ff6600" },
          height: 30,
        }}
        onClick={handlePrintReceipt} // Trigger the shareReceipt function here
      >
        Print Receipt
      </Button>

      <Receipt
        ref={receiptRef}
        recipient={recipient}
        refNo={refNo}
        service={transType}
        date={date}
        unit={unit}
        status={status}
        business={business}
      />
    </div>
  );
};

export default MobileTransactions;
